import '../App.css';
import Form from "../Form";
import HomeText from "./Home Components/HomeText";

function Home() {
  return (
    <div className="App">
      <header className="App-header">
        <HomeText />
        <Form />
      </header>
    </div>
  );
}

export default Home;