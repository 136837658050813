import '../../App.css'

function HomeText() {
  return (
    <div className="App">
      <header className="home_logo">
        CertiDash
      </header>
      <header className="home_slogan">
        Digitalising authenticity within the modified car community
      </header>
    </div>
  );
}

export default HomeText;