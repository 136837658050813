import useForm from "./UseForm";

const FORM_ENDPOINT = "https://r2zufrz046.execute-api.eu-west-1.amazonaws.com/dev"; // TODO - update to the correct endpoint

const Form = () => {
  const additionalData = {
    sent: new Date().toISOString(),
  };

  const { handleSubmit, status, message } = useForm({
    additionalData,
  });

  if (status === "success") {
    return (
      <>
        <div className="text-2xl">Thank you!</div>
        <div className="text-md">{message}</div>
      </>
    );
  }

  if (status === "error") {
    return (
      <>
        <div className="text-2xl">Something bad happened!</div>
        <div className="text-md">{message}</div>
      </>
    );
  }

  return (
    <form
      action={FORM_ENDPOINT}
      onSubmit={handleSubmit}
      method="POST"
    >
      <div className="pt-0 mb-3">
        <input
          type="email"
          placeholder="Email"
          name="email"
          className="form-control transparent"
          required
        />
        <button
          className="btn btn-danger btn-fill"
          type="submit"
        >
         Notify Me
        </button>
      </div>
      <div className="text-2xl">Register an interest to keep updated</div>
    </form>
  );
};

export default Form;