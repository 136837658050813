import '../App.css';
import Form from "../Form";

function News() {
  return (
    <div className="App">
      <header className="App-header">
      </header>
    </div>
  );
}

export default News;